<template>
	<b-col cols="12">
		<b-card>
			<ValidationObserver>
				<form @submit.prevent="saveItem">
					<div class="form-group">
						<label>Judul</label>
						<validation-provider
							name="Title"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								:class="classes"
								v-model="formPage.title"
								placeholder="Judul"
								:state="errors.length > 0 ? false:null"
							>
							<small
								v-for="(validation, index) in validations.title"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Slug *</label>
						<validation-provider
							name="Slug"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								class="form-control" 
								placeholder="Slug"
								:class="classes"
								:state="errors.length > 0 ? false:null"
								v-model="formPage.slug"
							>
							<span
								v-for="(validation, index) in validations.slug"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="form-group">
						<label>Content</label>
						<validation-provider
							name="Jenis Penghasilan"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<quill-editor
								v-model="formPage.content"
								:class="classes"
								:state="errors.length > 0 ? false:null"
								:options="snowOption"
							/>
							<span
								v-for="(validation, index) in validations.content"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
					</div>
					<div class="d-flex align-items-cennter">
						<button
							type="submit"
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
						>
							Save
						</button>
						&nbsp;
						&nbsp;
						<a
							@click="handleBackButton()"
							variant="secondary"
							class="btn waves-effect waves-float waves-light btn-secondary"
						>
							Cancel
						</a>
					</div>
				</form>
			</ValidationObserver>
		</b-card>
	</b-col>
</template>

<script>
import {
	BCard,
	BRow, 
	BFormGroup, 
	BFormRadio,  
	BCol,
	BTooltip,
	BFormSelect
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow, 
		BFormGroup, 
		BFormRadio,  
		BCol,
		BTooltip,
		BFormSelect,
		ValidationProvider,
		ValidationObserver,
		quillEditor
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return {
			required,
			formPage: {
				title: '',
				slug: '',
				content: '',
			},
			validations: '',
			snowOption: {
				theme: 'snow',
			},
		}
	},

	methods: {
		handleBackButton() {
			history.back()
		},


		saveItem() {
			const payload = new FormData()
			payload.append("title", this.formPage.title)
			payload.append("slug", this.formPage.slug)
			payload.append("content", this.formPage.content)
			this.$http.post('admin/pages', payload ,{
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			}).then(response => {
				successNotification(this, 'Success', 'Page sukses dibuat!')
				this.$router.push({ name: 'page' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Judul', this.validations.title.join(' '))
					errorNotification(this, 'Slug', this.validations.slug.join(' '))
				}
			})
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
	.tooltip_hint {
		border-radius: 50%;
		background: #000;
		color: #fff;
		padding: 0px 4px;
	}
</style>